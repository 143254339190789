import { randomBytes } from 'crypto'
import dayjs from 'dayjs'

export const Substr = (text: string, max: number, complete = '...'): string => {
  return text.length > max ? `${text.substring(0, max)}${complete}` : text
}

export const CpfValid = (cpf: string) => {
  var cpfRegex = /^(?:(\d{3}).(\d{3}).(\d{3})-(\d{2}))$/
  if (!cpfRegex.test(cpf)) {
    return false
  }

  if (cpf.length === 0) {
    return false
  }

  const c = cpf.match(/\d/g)
  if (!c) {
    return false
  }
  var numeros = c.map(Number)
  var soma = numeros.reduce((acc, cur, idx) => {
    if (idx < 9) {
      return acc + cur * (10 - idx)
    }
    return acc
  }, 0)

  var resto = (soma * 10) % 11

  if (resto === 10 || resto === 11) {
    resto = 0
  }

  if (resto !== numeros[9]) {
    return false
  }

  soma = numeros.reduce((acc, cur, idx) => {
    if (idx < 10) {
      return acc + cur * (11 - idx)
    }
    return acc
  }, 0)

  resto = (soma * 10) % 11

  if (resto === 10 || resto === 11) {
    resto = 0
  }

  if (resto !== numeros[10]) {
    return false
  }

  return true
}

export const MaskCpf = (value: string): string => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})/, '$1.')
    .replace(/(\d{3})\.(\d{3})/, '$1.$2.')
    .replace(/(\d{3})\.(\d{3})\.(\d{3})/, '$1.$2.$3-')
    .replace(/(\d{3})\.(\d{3})\.(\d{3})\-(\d{2})/, '$1.$2.$3-$4')
}

export const MaskCep = (value: string): string => {
  return value.replace(/\D/g, '').replace(/^(\d{5})(\d)/, '$1-$2')
}

export const RegexName = (name: string, words: string[]): boolean => {
  const names = name.trim().split(' ')
  return (
    words.filter((item) => {
      return names.filter((n) => item.search(n) !== -1).length !== 0
    }).length !== 0
  )
}

export const FormatMoney = (
  currencyValue: string,
  lang: string,
  currency: string
): string => {
  const isNegative = currencyValue.startsWith('-')
  const currencyString = currencyValue.replace(/\D/g, '')
  const first = currencyString.substring(0, currencyString.length - 2)
  const decimal = currencyString.substring(
    currencyString.length - 2,
    currencyString.length
  )
  if (first.length === 0 && decimal.length === 0) {
    return ''
  }
  if (first.length === 0) {
    const formattedValue = parseFloat(`0.0${decimal}`).toLocaleString(lang, {
      style: 'currency',
      currency: currency
    })
    return isNegative ? `-${formattedValue}` : formattedValue
  }
  const formattedValue = parseFloat(`${first}.${decimal}`).toLocaleString(
    lang,
    {
      style: 'currency',
      currency: currency
    }
  )
  return isNegative ? `-${formattedValue}` : formattedValue
}

export const FindObject = (id: number | string, items: any[]): string => {
  const search = items.find((item) => item.id === id)

  if (!search) return '...'

  return search.text
}

export function simulateLongLoad(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time))
}

export function FormatTimeStampDate(lang: string, timestamp: number) {
  const date = new Date(timestamp * 1000)

  const formatter = new Intl.DateTimeFormat(lang, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  })

  return formatter.format(date)
}

export function TranslateInterval(interval: 'day' | 'week' | 'month' | 'year') {
  const translations = {
    day: 'Diário',
    week: 'Semanal',
    month: 'Mensal',
    year: 'Anual'
  }

  return translations[interval] || interval
}

export function timeAgo(dateParam: string) {
  const past = new Date(dateParam)
  const now = new Date()

  const utcPast = Date.UTC(
    past.getUTCFullYear(),
    past.getUTCMonth(),
    past.getUTCDate(),
    past.getUTCHours(),
    past.getUTCMinutes(),
    past.getUTCSeconds()
  )
  const utcNow = Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds()
  )
  const elapsed = Math.abs(utcNow - utcPast)

  const seconds = Math.round(elapsed / 1000)
  const minutes = Math.round(elapsed / 60000)
  const hours = Math.round(elapsed / 3600000)
  const days = Math.round(elapsed / 86400000)
  const weeks = Math.round(elapsed / 604800000)
  const months = Math.round(elapsed / 2629800000)
  const years = Math.round(elapsed / 31557600000)

  if (seconds < 60) return `${seconds} segundos atrás`
  else if (minutes < 60) return `${minutes} minutos atrás`
  else if (hours < 24) return `${hours} horas atrás`
  else if (days < 7) return `${days} dias atrás`
  else if (weeks < 5) return `${weeks} semanas atrás`
  else if (months < 12) return `${months} meses atrás`
  else return `${years} anos atrás`
}

export type UnchangedValuesProps = {
  [key: string]: any
}
export function removeUnchangedValues<T extends UnchangedValuesProps>(
  initialValues: T,
  formValues: T
): Partial<T> {
  let result: Partial<T> = {}

  for (let key in formValues) {
    if (formValues[key] !== initialValues[key]) {
      result[key] = formValues[key]
    }
  }

  return result
}

export function isEmpty(obj: object) {
  return Object.keys(obj).length === 0
}

export function extractionFileName(filepath: string) {
  const parts = filepath.split('/')
  return parts.length > 1 ? parts.pop() : filepath
}

export const formatDateAsBRT = (): string => {
  const now = new Date()
  const year = now.getFullYear().toString().padStart(4, '0')
  const month = (now.getMonth() + 1).toString().padStart(2, '0')
  const day = now.getDate().toString().padStart(2, '0')
  const hours = now.getHours().toString().padStart(2, '0')
  const minutes = now.getMinutes().toString().padStart(2, '0')
  const seconds = now.getSeconds().toString().padStart(2, '0')

  return `${day}-${month}-${year}-${hours}-${minutes}-${seconds}`
}

export function hexToRGBA(hex: string, opacity: number): string {
  const r = parseInt(hex.substring(1, 3), 16)
  const g = parseInt(hex.substring(3, 5), 16)
  const b = parseInt(hex.substring(5, 7), 16)

  return `rgba(${r},${g},${b},${opacity})`
}

export function formatPhoneNumber(input: string | null | undefined): string {
  if (!input) {
    return '' // return an empty string or whatever default you want
  }

  const cleanedInput = input.replace(/\D/g, '')
  const ddi = cleanedInput.slice(0, 2) // Country code
  const ddd = cleanedInput.slice(2, 4) // Area code
  const firstPart = cleanedInput.slice(4, 8) // First part of the number
  const secondPart = cleanedInput.slice(8) // Second part of the number

  return `${ddi} (${ddd}) ${firstPart}-${secondPart}`
}

export function sanitizeString(input: string): string {
  return input
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/ç/g, 'c')
    .replace(/Ç/g, 'C')
    .replace(/\s+/g, '_')
    .replace(/[^a-zA-Z0-9_.]/g, '')
}

export function generateRandomHex(length: number): string {
  const randomBytesBuffer = randomBytes(length)
  return randomBytesBuffer.toString('hex')
}

type MapaAcentos = { [chave: string]: string }

export function normalizarTexto(texto: string): string {
  const mapaAcentos: MapaAcentos = {
    Á: 'A',
    á: 'a',
    Â: 'A',
    â: 'a',
    À: 'A',
    à: 'a',
    Ã: 'A',
    ã: 'a',
    É: 'E',
    é: 'e',
    Ê: 'E',
    ê: 'e',
    Í: 'I',
    í: 'i',
    Ó: 'O',
    ó: 'o',
    Ô: 'O',
    ô: 'o',
    Õ: 'O',
    õ: 'o',
    Ú: 'U',
    ú: 'u',
    Ü: 'U',
    ü: 'u',
    Ç: 'C',
    ç: 'c'
  }

  // Substitui caracteres acentuados por não acentuados
  let textoSemAcentos = texto.replace(
    /[\u00C0-\u00FF]/g,
    (caractere) => mapaAcentos[caractere] || caractere
  )

  // Remove caracteres especiais, mantendo apenas letras e números
  let textoNormalizado = textoSemAcentos.replace(/[^a-zA-Z0-9 ]/g, '')

  return textoNormalizado
}

interface calculatePerformance {
  totalDislikes?: number | null
  totalMessages?: number | null
}

export function calculatePerformance({
  totalDislikes = 0,
  totalMessages = 1
}: calculatePerformance) {
  const totalMsg = totalMessages ?? 1
  const totalDislikesValue = totalDislikes ?? 0
  const performance = (1 - totalDislikesValue / totalMsg) * 100 || 0
  return parseFloat(performance.toFixed(2))
}

export function getStringDate(date?: string, hasTime = false): string {
  return dayjs(date).format(hasTime ? 'DD/MM/YYYY - H:m' : 'DD/MM/YYYY');
}

export function truncateString(str: string, maxLength: number) {
  return str.length > maxLength ? str.slice(0, maxLength - 3) + '...' : str
}


export function getYouTubeVideoId(url: string): string | null {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
  const match = url.match(regex)
  return match ? match[1] : null
}
